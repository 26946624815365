<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.app_code" @change="Search" placeholder="客户端的终端code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.platform" @change="Search" placeholder="平台标识">
            <el-option label="安卓" :value="1"></el-option>
            <el-option label="IOS" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model.trim="search.version_number" @change="Search" placeholder="版本编号"></el-input>
        </el-form-item> -->
      </el-form>
      <div>
        <router-link :to="'/iot/version/forceupgrade/update/'+0">
          <el-button type="primary" size="small" >新建</el-button>
        </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="终端编号">
        <template v-slot="scope">
          <span>{{ clientlist[scope.row.app_code] }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="强制更新">
        <template v-slot="scope">
          <span>{{ scope.row.is_must==1?'强制':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商城连接地址">
        <template v-slot="scope">
          <span>{{ scope.row.link }}</span>
        </template>
      </el-table-column>
      <el-table-column label="包名称">
        <template v-slot="scope">
          <span>{{ scope.row.package_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="包大小">
        <template v-slot="scope">
          <span>{{ scope.row.package_size }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="平台标识">
        <template v-slot="scope">
          <span>{{ scope.row.platform==1?'安卓':'IOS' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最小APP版本号">
        <template v-slot="scope">
          <span>{{ scope.row.version_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <span>{{ scope.row.status === 1 ? "撤销" : scope.row.status === 2 ? "测试" :  scope.row.status === 3 ? "发布" : ""  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本包更新日志">
        <template v-slot="scope">
          <!-- <span>{{ scope.row.log }}</span> -->
          <el-button type="info" size="mini" @click="Look(scope.row.log)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/version/forceupgrade/update/'+scope.row.code">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteSoft(scope.row.code)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%">
      {{Log}}
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        app_code: '',
        platform: '',
      },
      list: [],
      total: 0,
      dialogVisible: false,
      Log: {},
      clientlist: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetClientcode () {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.clientlist[item.code] = item.name
          });
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search() {
      this.api.ForceUpgradeList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteSoft(item) {
      this.$confirm('你确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ForceUpgradDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch((e) => {
        console.log(e)
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Look (item) {
      this.Log = JSON.parse(item)
      this.dialogVisible = true
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.GetClientcode()
  },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
